import { Swiper } from "swiper";
// import { Navigation } from "swiper/modules";

const productsSlider = new Swiper(".watch-now__slider", {
  // modules: [Navigation],
  slidesPerView: "auto",
  spaceBetween: 8,
  // navigation: {
  //   prevEl: ".s-products .slider-navigation-arrow--prev",
  //   nextEl: ".s-products .slider-navigation-arrow--next",
  // },
  breakpoints: {
    992: {
      spaceBetween: 12,
    },
  },
  resistance: true,
  resistanceRatio: 1,
});
