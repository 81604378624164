// Получаем элементы формы
const emailInput = document.querySelector(".soon-subscription__form-input");
const submitButton = document.querySelector(".soon-subscription__form-button");
const snackbar = document.querySelector(".snackbar");
const snackbarCloseButton = document.querySelector(".snackbar__button");

// Добавляем обработчик события клика на кнопку
submitButton.addEventListener("click", () => {
  // Проверяем валидность email
  if (validateEmail(emailInput.value)) {
    // Очищаем поле ввода
    emailInput.value = "";
    // Добавляем класс is-show к элементу snackbar
    snackbar.classList.add("is-show");
  }
  // Удаляем класс is-show через 5 секунд
  setTimeout(() => {
    snackbar.classList.remove("is-show");
  }, 5000);
});

// Функция для валидации email
function validateEmail(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

// Добавляем обработчик события клика на кнопку закрытия snackbar
snackbarCloseButton.addEventListener("click", () => {
  snackbar.classList.remove("is-show");
});
